import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';

const ERRORS = {
  e403: {
    title: 'Falha (403)', 
    message: 'Falha na requisição. URL não encontrada'
  },
  e404: {
    title: 'Falha (404)', 
    message: 'Falha na requisição. URL não encontrada'
  },
  e422: {
    title: 'Falha (422)', 
    message: 'Houve um problema em sua solicitação. ' +
              'Contate  o suporte e tente novamente mais tarde!'
  },
  e500: {
    title: 'Falha (500)', 
    message: 'Houve um problema no servidor. ' +
              'Contate  o suporte e tente novamente mais tarde!'
  },
  unmapped: {
    title: 'Falha geral', 
    message: 'Falha ao realizar a solicitação. ' +
              'Contate o suporte e tente novamente mais tarde!'
  }
}

@Injectable({
  providedIn: 'root'
})
export class HttpExceptionService {

  
  constructor(
    private toastrService: ToastrService
  ) {}

  handlerError(error: HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      const errorMessage = error.error || error.message;
      switch (error.status) {
        case 422:
          this.throwErrorToastr({
            title: ERRORS.e422.title,
            message: this.getMessagesArrayError(errorMessage) || ERRORS.e422.message,
          });
          break;
        case 403:
          this.throwErrorToastr({
            title: ERRORS.e403.title,
            message: this.getMessagesArrayError(errorMessage) || ERRORS.e403.message,
          });
          break;
        case 404:
          this.throwErrorToastr({
            title: ERRORS.e404.title,
            message: this.getMessagesArrayError(errorMessage) || ERRORS.e404.message,
          });
          break;
        case 500:
          this.throwErrorToastr({
            title: ERRORS.e500.title,
            message: this.getMessagesArrayError(errorMessage) || ERRORS.e500.message,
          });
          break;
        default:
          this.throwErrorToastr(ERRORS.unmapped);
          break;
      }
    } else {
      this.throwErrorToastr(ERRORS.unmapped);
    }
    return throwError(error);
  }

  private getMessagesArrayError(value: any): string | undefined {
    if (!value || !(value instanceof Object)) {
      return undefined;
    }
    let messages: string | undefined = '';
      Object.keys(value.errors).map((key) => { 
        messages = messages + value.errors[key] + '\n';
        return value.errors[key]; 
      });
      return messages !== '' ? messages : undefined;
  }

  private throwErrorToastr(errorMessage: { title: string; message: string; }): void {
    this.toastrService.error(
      errorMessage.message, errorMessage.title
    );
  }
}
