export enum PersonTypes {
    DENTIST = 'dentist',
    CUSTOMER = 'customer'
}

export function toPersonType(value: string | null): PersonTypes | undefined {
    switch (value) {
        case PersonTypes.DENTIST:
            return PersonTypes.DENTIST
        case PersonTypes.CUSTOMER:
            return PersonTypes.CUSTOMER
        default:
            return undefined;
    }
}

export function translatePersonType(personType: PersonTypes | undefined): string{
    switch (personType) {
        case PersonTypes.DENTIST:
            return 'dentista'
        case PersonTypes.CUSTOMER:
            return 'paciente'
        default:
            return '';
    }
}