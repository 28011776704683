import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private $loading: BehaviorSubject<any> = new BehaviorSubject(false);
  onLoading = this.$loading.asObservable();

  constructor() {
  }

  show() {
    this.$loading.next(true);
  }

  hide() {
    this.$loading.next(false);
  }
}
