import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingService } from 'projects/component-library/src/public-api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpExceptionService } from '../shared/services/http-exception.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    
    constructor(
        private httpExceptionService: HttpExceptionService,
        private loadingService: LoadingService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                'Content-Type': 'application/json',
                'Accept': '*/*'
            }
        });
        this.loadingService.show();
        return next.handle(request).pipe(
            tap(
                () => {},
                (error: HttpEvent<any>) => this.loadingService.hide(),
                () => this.loadingService.hide()
            )
        )
    }

}