import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingModule } from 'projects/component-library/src/public-api';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { IconCircleModule } from 'projects/component-library/src/public-api';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppGuard } from './guards/app-guard';
import { HttpInterceptorService } from './interceptors/http-interceptor';
import { environment } from '../environments/environment';

declare var amplitude: any;

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    IconCircleModule,
    LoadingModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot()
  ],
  providers: [
    AppGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    amplitude.getInstance().init(environment.amplitudeApiKey);
  }
}
