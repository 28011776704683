import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconCircleComponent } from './icon-circle.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { 
  faCoffee,
  faTooth,
  faCalendarPlus,
  faHistory,
  faUserClock, 
  faExclamation
} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [
    IconCircleComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    FontAwesomeModule
  ],
  exports: [
    IconCircleComponent
  ]
})
export class IconCircleModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCoffee);
    library.addIcons(faTooth);
    library.addIcons(faCalendarPlus);
    library.addIcons(faHistory);
    library.addIcons(faUserClock);
    library.addIcons(faExclamation)
  }
}
