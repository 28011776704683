import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { EstimateService } from '../../modules/estimate/shared/services/estimate.service';

@Injectable({
  providedIn: 'root'
})
export class HttpApiService {

  private baseUrl = environment.baseUrl;
  private urlType!: string;
   
  private clientKey!: string;
  private $clientKey: BehaviorSubject<any> = new BehaviorSubject(null);
  onClientKey = this.$clientKey.asObservable();
  

  private api!: string;
  private $api: BehaviorSubject<any> = new BehaviorSubject(null);
  
  onApi = this.$api.asObservable();

  constructor(
    private activatedRoute: ActivatedRoute,
    private estimateService: EstimateService
  ) {
    this.$clientKey
    .pipe(filter(x => !!x))
    .subscribe(clientKey => {
      this.$api
      .pipe(filter(x => !!x))
      .subscribe(api => {
        this.clientKey = clientKey;
        this.api = api
        this.baseUrl = `${this.baseUrl}/${clientKey}`

        if(api)
        this.baseUrl = `${this.baseUrl.replace("app", api)}`;
      })
    })
  }


  getBasePath(): string{
    return this.baseUrl;
  }


  getClientKey(){
    return this.clientKey
  }

  setClientKey(clientKey: string): void {
    if (!this.clientKey) {
      this.$clientKey.next(clientKey);
    }
  }

  setApi(api: string): void {
    if (!this.api) {
      this.$api.next(api);
    }
  }

  getApi(){
    return this.api
  }
}
