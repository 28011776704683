import { Component, AfterViewInit, Renderer2, AfterViewChecked, ViewChild, ElementRef, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, AfterViewChecked{
  title = 'signature-app';
  @ViewChild('scrollMe')
  private myScrollContainer!: ElementRef;
  private currentRoute!: any; 

  constructor(private router: Router, private route: ActivatedRoute, private elem: ElementRef) {
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event): void {
    event.preventDefault();
  }
  ngOnInit() {
    
      // this.scrollToBottom();
      // document.ontouchmove = function (e) {
      //   // Previne o scroll para dispositivos iOS antigos
      //   e.preventDefault();
      // };
  }

  ngAfterViewChecked() {    
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let route = this.router.routerState.root;
        while (route.firstChild) {
          route = route.firstChild;
        }

        this.currentRoute = (route.component ?  route.routeConfig?.component?.name : '')
      }
    }); 
    const height = window.screen.height;
    if((this.currentRoute == 'EstimateFormComponent' || this.currentRoute == 'AnamneseSignatureComponent' || this.currentRoute == 'TreatmentEvolutionSignatureComponent') && height < 500){
    var appContainer = this.elem.nativeElement.querySelectorAll('.app-container')
    if(appContainer)
      appContainer[0].style.overflowY = "hidden"
    this.scrollToBottom();   
    }
  } 

  scrollToBottom(): void {
      try {
          this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      } catch(err) { }                 
  }
}