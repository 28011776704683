import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-circle',
  templateUrl: './icon-circle.component.html',
  styleUrls: ['./icon-circle.component.scss']
})
export class IconCircleComponent implements OnInit {

  @Input()
  public matIcon!: string;
  @Input()
  public srcIcon!: string;
  @Input()
  public faIcon!: any;

  constructor() { }

  ngOnInit(): void {
  }

}
