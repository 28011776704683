import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PersonTypes } from '../enums/person-type';
import { AllSignature, SignatureAllPersons, SignatureData, SignaturePersonData } from '../interfaces/signature.model';
import { EstimateData, PersonData } from '../interfaces/estimate-response.model';
import { filter } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class EstimateService {

  private estimateId!: string;
  
  private personType!: PersonTypes;
  private $personType: BehaviorSubject<any> = new BehaviorSubject(null);

  private signatureData!: SignatureData;
  private $signatureData: BehaviorSubject<any> = new BehaviorSubject(null);

  private estimateResponse!: EstimateData;
  private $estimateResponse: BehaviorSubject<any> = new BehaviorSubject(null);
  


  public onEstimateResponse = this.$estimateResponse.asObservable();
  public onSignatureData = this.$signatureData.asObservable();
  public onPersonType = this.$personType.asObservable();
  
  constructor() {
    this.listenEstimateResponse();
    this.listenPersonType();
    this.listenSignatureData();
  } 

  canActivateByPersonType(personType: string): boolean {
    if (personType === PersonTypes.CUSTOMER) {
      return this.isDentistComplete();
    }
    return true;
  }

  setEstimateResponse(estimate: EstimateData): void {
    this.$estimateResponse.next(estimate);
  }

  setPersonType(personType: PersonTypes): void {
    this.$personType.next(personType);
  }

  setEstimateId(estimateId: string): void {
    this.estimateId = estimateId;
  }

  getEstimateId(): string {
    return this.estimateId;
  }

  findDataSessionStorage(): void {
    const signatureData = sessionStorage.getItem('signatureData');
    if (signatureData) {
      this.$signatureData.next(JSON.parse(signatureData));
    }
  }

  clearSignatureDataStorage(): void {
    sessionStorage.setItem('signatureData', '');
    this.signatureData = {};
    this.$signatureData.next(undefined);
  }

  setFormData(data: SignatureAllPersons): void {
    if (!this.signatureData) {
      this.signatureData = {};
    }

    const modelDentist = {
      name: data.name,
      email: data.email,
      cpf: data.cpf,
      birth_date: data.birth_date
    }

    const modelCustomer = {
      name: data.name_customer,
      email: data.email_customer,
      cpf: data.cpf_customer,
      birth_date: data.birth_date_customer,
      cellphone: data.cellphone_customer
    }
      this.signatureData.treatment_id = data.treatment_id
      this.signatureData.user_id = data.user_id;
      this.signatureData.dentist = modelDentist;
      this.signatureData.customer = modelCustomer;

    this.$signatureData.next(this.signatureData);
  }

  setSignatureData(data: AllSignature): void {
    if(this.personType === PersonTypes.DENTIST){
      this.signatureData.dentist != undefined ? this.signatureData.dentist.signature 
      = data.signature : undefined;
    }
    if(this.personType === PersonTypes.CUSTOMER){
      this.signatureData.customer != undefined ? this.signatureData.customer.signature
       = data.signature : undefined;
    }
    this.$signatureData.next(this.signatureData);
  }

  private listenEstimateResponse(): void {
    this.onEstimateResponse.subscribe(x => {
      this.estimateResponse = x;
    });
  }

  private listenPersonType(): void {
    this.onPersonType.subscribe(x => {
      this.personType = x;
    });
  }
  
  private listenSignatureData(): void {
    this.onSignatureData
      .pipe(filter(x => !!x))
      .subscribe(x => {
        this.signatureData = x;
        sessionStorage.setItem(
          'signatureData',
          JSON.stringify(this.signatureData)
        );
      });
  }

  setType(type: string): void{
    sessionStorage.setItem(
      'type',
      type
    );
  }

  getType(){
    return sessionStorage.getItem('type');
  }

  setPrint(print: string): void{
    sessionStorage.setItem(
      'print_notes',
      print
    );
  }

  getPrint(){
    return sessionStorage.getItem('print_notes');
  }

  private isDentistComplete():  boolean {
    // const estimate: any = this.signatureData;
    // return estimate && estimate[PersonTypes.DENTIST]? true: false;
    return true;
  }
}